import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import {
  LOCALSTORAGE_USER_MAIL,
  LOCALSTORAGE_USER_NAME,
  PATH_EDITOR,
} from 'src/app/utils/cst';
import { ConfigurationService } from '../../../services/configuration.service';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  public userProfile: KeycloakProfile;
  public isAuthenticated: boolean;

  constructor(
    private data: AuthService,
    private router: Router,
    private configurationService: ConfigurationService,
    private translate: TranslateService,
    private readonly keycloakService: KeycloakService
  ) {}

  public async ngOnInit() {
    this.isAuthenticated = await this.keycloakService.isLoggedIn();

    if (this.isAuthenticated) {
      this.userProfile = await this.keycloakService.loadUserProfile();
      const token = await this.keycloakService.getToken();
      const refreshToken =
        this.keycloakService.getKeycloakInstance().refreshToken;
      this.auth(token, refreshToken);
    } else {
      this.keycloakService.login();
    }
  }

  auth(token: string, refreshToken: string) {
    this.data
      .checkTokenSession(token, refreshToken, this.translate.currentLang)
      .subscribe((responseOK) => {
        // clear the previous local storage except userEmail
        const userEmail = localStorage.getItem(LOCALSTORAGE_USER_MAIL);
        if (userEmail) {
          localStorage.setItem(LOCALSTORAGE_USER_MAIL, userEmail);
        }
        // store userName
        localStorage.setItem(LOCALSTORAGE_USER_NAME, responseOK.name);
        this.router.navigate([PATH_EDITOR]);
      });
  }

  public keycloakLogin() {
    this.keycloakService.login();
  }

  public keycloakLogout() {
    this.keycloakService.logout();
  }
}
