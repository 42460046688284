import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  CHECK_TOKEN_SESSION_URL,
  CREATE_SESSION_URL,
  LOCALSTORAGE_REFRESH_TOKEN,
  LOCALSTORAGE_TOKEN,
  LOGOUT_URL,
  PATH_LOGIN,
} from '../utils/cst';
import { ReLoginModalComponent } from '../views/authentification/re-login-modal/re-login-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private keycloak: KeycloakService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const refreshToken = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN);
    let request;
    // if user is logged in, we pass the token to the server in each request:
    if (token) {
      const headers =
        req.url.includes('/upload') ||
        req.url.includes('/import') ||
        req.url.includes('/convert') ||
        req.url.includes('/template')
          ? new HttpHeaders({
              Authorization: 'Bearer ' + token,
            })
          : refreshToken &&
            !req.url.includes('/flattened-array') && // Avoid hundreds of refresh in a row
            !req.url.includes('/details')
          ? new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
              refreshToken: refreshToken,
            })
          : new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            });
      request = req.clone({ headers, withCredentials: true });
    } else {
      const headers = new HttpHeaders(); // Empty headers
      request = req.clone({ headers, withCredentials: true });
    }

    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response.headers.has(LOCALSTORAGE_TOKEN)) {
            const token = response.headers.get(LOCALSTORAGE_TOKEN);
            localStorage.setItem(LOCALSTORAGE_TOKEN, token);
            this.keycloak.getKeycloakInstance().token = token;
          }
          if (response.headers.has(LOCALSTORAGE_REFRESH_TOKEN)) {
            const refreshToken = response.headers.get(
              LOCALSTORAGE_REFRESH_TOKEN
            );
            localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, refreshToken);
            this.keycloak.getKeycloakInstance().refreshToken = refreshToken;
          }
        }
      }),
      catchError((err) => {
        if (
          (err.status === 403 || err.status === 401) &&
          request.url !== CREATE_SESSION_URL &&
          request.url !== CHECK_TOKEN_SESSION_URL
        ) {
          this.spinner.hide();
          localStorage.removeItem(LOCALSTORAGE_TOKEN);
          if (!ReLoginModalComponent.modalOpen && request.url !== LOGOUT_URL) {
            this.dialog.open(ReLoginModalComponent, { disableClose: true });
          }
          return EMPTY;
        } else if (err.status === 0 || err.status === 503) {
          localStorage.clear();
          this.spinner.hide();
          this.toaster.error(
            this.translate.instant('services.backend-unreachable')
          );
          this.router.navigate([PATH_LOGIN]);
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }
}
