import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  LOCALSTORAGE_REFRESH_TOKEN,
  LOCALSTORAGE_TOKEN,
  PATH_EDITOR,
  PATH_LOGIN,
} from './cst';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class Dispatcher {
  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService
  ) {}

  async canActivate() {
    if (
      (await this.keycloakService.isLoggedIn()) &&
      localStorage.getItem(LOCALSTORAGE_TOKEN) == null &&
      localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN) == null
    ) {
      this.router.navigate([PATH_LOGIN]);
    } else if (
      (await this.keycloakService.isLoggedIn()) &&
      localStorage.getItem(LOCALSTORAGE_TOKEN) != null &&
      localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN) != null
    ) {
      this.router.navigate([PATH_EDITOR]);
    } else {
      this.keycloakService.login();
    }
  }
}
