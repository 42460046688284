import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventService {
  private _ctrlDown = false;
  private _altDown = false;
  private _shiftDown = false;
  private _keyF2Pressed$: Subject<void> = new Subject<void>();
  private _keyCtrlDown$: Subject<boolean> = new Subject<boolean>();
  private _keyCtrlUp$: Subject<boolean> = new Subject<boolean>();

  public get ctrlDown(): boolean {
    return this._ctrlDown;
  }

  public get altDown(): boolean {
    return this._altDown;
  }

  public get shiftDown(): boolean {
    return this._shiftDown;
  }

  public get anyModifier(): boolean {
    return this._ctrlDown || this._altDown || this._shiftDown;
  }

  public get keyF2Pressed$(): Subject<void> {
    return this._keyF2Pressed$;
  }

  public get keyCtrlDown$(): Subject<boolean> {
    return this._keyCtrlDown$;
  }

  public get keyCtrlUp$(): Subject<boolean> {
    return this._keyCtrlUp$;
  }

  constructor() {}

  setCtrlDown() {
    this._ctrlDown = true;
    this._keyCtrlDown$.next(this.ctrlDown);
  }

  setCtrlUp() {
    this._ctrlDown = false;
    this._keyCtrlUp$.next(this.ctrlDown);
  }

  setAltDown() {
    this._altDown = true;
  }

  setAltUp() {
    this._altDown = false;
  }

  setShiftDown() {
    this._shiftDown = true;
  }

  setShiftUp() {
    this._shiftDown = false;
  }
}
