import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USER_MAIL } from '../../../utils/cst';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-re-login-modal',
  templateUrl: './re-login-modal.component.html',
  styleUrls: ['./re-login-modal.component.less'],
})
export class ReLoginModalComponent {
  static modalOpen;

  @ViewChild('email') emailField: ElementRef;
  @ViewChild('password') passwordField: ElementRef;

  isMailSaved = localStorage.getItem(LOCALSTORAGE_USER_MAIL) !== null;
  mail: string;
  pass: string;

  // *******************************************************************************************************************
  // INITIALIZATION
  // *******************************************************************************************************************

  constructor(
    public activeModal: MatDialogRef<ReLoginModalComponent>,
    private router: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private authService: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) {
    this.mail = localStorage.getItem(LOCALSTORAGE_USER_MAIL);
    this.activeModal.afterOpened().subscribe(() => {
      ReLoginModalComponent.modalOpen = true;
      if (this.isMailSaved) {
        this.passwordField.nativeElement.focus();
      } else {
        this.emailField.nativeElement.focus();
      }
    });
    this.activeModal.afterClosed().subscribe(() => {
      ReLoginModalComponent.modalOpen = false;
      if (localStorage.getItem(LOCALSTORAGE_TOKEN) == null) {
        this.authService.logOut();
      } else {
        document.location.reload();
      }
    });
  }

  onAuth() {
    this.spinner.show();
    this.authService
      .login(this.mail, this.pass, this.translate.currentLang)
      .subscribe(
        (ok) => {
          this.activeModal.close(true);
          this.spinner.hide();
        },
        (ko) => {
          this.toast.error(this.translate.instant('authentification.error'));
          this.spinner.hide();
        }
      );
  }

  onCancel() {
    this.activeModal.close(false);
  }
}
